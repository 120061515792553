.ant-picker-range {
  width: 100%;
}
.addMoreGrid .ant-row {
  padding-right: 0.7rem !important;
}
.addMore .ant-form-item {
  margin: 0 0 10px !important;
}
.answerRemoveLabel label {
  visibility: hidden;
}